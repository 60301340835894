module.exports = [
  {
    "collection_name": "full",
    "collection_description": "text"
  },
  {
    "id" : "1",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-big",
    "name" : "gray black",
    "picture_name" : "gray<br>black",
    "materials" : "canvas, acrylics",
    "size" : "80x40cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/ABSTRACT/gray_black_80x40.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/ABSTRACT_thumball/gray_blAck.png",
    "text" : ""
  },
  {
    "id" : "2",
    "collection": "full",
    "status" : "fill",
    "type" : "horizontal-small",
    "name" : "through the window",
    "picture_name" : "throu-<br>gh the<br>wind-<br>ow",
    "materials" : "canvas, acrylics",
    "size" : "40x40cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/NATURE%20MORTE/through_the_window_40x40.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/NATURE%20MORTE_thumball/through_the_window.jpg",
    "text" : ""
  },
  {
    "id" : "3",
    "status" : "empty"
  },
  {
    "id" : "4",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-small",
    "name" : "phistashion",
    "picture_name" : "phista-<br>shion",
    "materials" : "canvas, acrylics",
    "size" : "80x40cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/STRANGERS/phistashion_80x40.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/STRANGERS_thumball/phistashion.jpg",
    "text" : ""
  }, 
  {
    "id" : "5",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-big",
    "name" : "abstrct00",
    "picture_name" : "abstr-<br>ct00",
    "materials" : "canvas, acrylics",
    "size" : "60x40cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/ABSTRACT/abstrct00_60x40.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/ABSTRACT_thumball/abstrct00.png",
    "text" : ""
  },
  {
    "id" : "6",
    "status" : "empty"
  },
  {
    "id" : "7",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-small",
    "name" : "guard01",
    "picture_name" : "guard<br>01",
    "materials" : "canvas, acrylics",
    "size" : "60x40cm",
    "year" : "2020",
    "image": "https://nnitiput.sirv.com/Images/STRANGERS/guard01_60x40.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/STRANGERS_thumball/guard01.jpg",
    "text" : ""
  },
  {
    "id" : "8",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-big",
    "name" : "guard02",
    "picture_name" : "guard<br>02",
    "materials" : "canvas, acrylics",
    "size" : "70x50cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/STRANGERS/guard02_70x50.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/STRANGERS_thumball/guard02.jpg",
    "text" : ""
  },
  {
    "id" : "9",
    "status" : "empty"
  },
  {
    "id" : "10",
    "collection": "full",
    "status" : "fill",
    "type" : "horizontal-small",
    "name" : "space02",
    "picture_name" : "space<br>02",
    "materials" : "canvas, acrylics",
    "size" : "29x21cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/ABSTRACT/space02_29x21.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/ABSTRACT_thumball/SPACE02.png",
    "text" : ""
  },
  {
    "id" : "11",
    "collection": "full",
    "status" : "fill",
    "type" : "horizontal-small",
    "name" : "dutch01",
    "picture_name" : "dutch<br>01",
    "materials" : "canvas, acrylics",
    "size" : "40x50cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/NATURE%20MORTE/dutch01_40x50.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/NATURE%20MORTE_thumball/dutch01.jpg",
    "text" : ""
  },
  {
    "id" : "12",
    "status" : "empty"
  },
  {
    "id" : "13",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-big",
    "name" : "goatman",
    "picture_name" : "goat-<br>man",
    "materials" : "canvas, acrylics",
    "size" : "70x50cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/NATURE%20MORTE/goatman_70x50.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/NATURE%20MORTE_thumball/goatman.jpg",
    "text" : ""
  },
  {
    "id" : "14",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-small",
    "name" : "khepri",
    "materials" : "canvas, acrylics",
    "size" : "40x50cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/STRANGERS/khepri_40x50.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/STRANGERS_thumball/kherpi.png",
    "text" : ""
  },
  {
    "id" : "15",
    "status" : "empty"
  },
  {
    "id" : "16",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-small",
    "name" : "manifesto",
    "picture_name" : "mani-<br>festo",
    "materials" : "canvas, acrylics",
    "size" : "40x50cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/ABSTRACT/manifesto_40x50.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/ABSTRACT_thumball/manifesto_40x50.png",
    "text" : ""
  },
  {
    "id" : "17",
    "collection": "full",
    "status" : "fill",
    "type" : "horizontal-small",
    "name" : "the void",
    "picture_name" : "the<br>void",
    "materials" : "digital",
    "size" : "40x40cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/GRAPHICS/the_void_40x50.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/GRAPHICS_thumball/the_void.jpg",
    "text" : ""
  },
  {
    "id" : "18",
    "status" : "empty"
  },
  {
    "id" : "19",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-small",
    "name" : "black tower",
    "picture_name" : "black<br>tower",
    "materials" : "digital",
    "size" : "50x60cm",
    "year" : "2019",
    "image":"https://nnitiput.sirv.com/Images/GRAPHICS/black_tower_70x50.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/GRAPHICS_thumball/black_tower.jpg",
    "text" : ""
  },
  {
    "id" : "20",
    "collection": "full",
    "status" : "fill",
    "type" : "horizontal-small",
    "name" : "all white 1",
    "picture_name" : "all<br>white 1",
    "materials" : "canvas, acrylics",
    "size" : "50x40cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/ABSTRACT/all_white1_40x40.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/ABSTRACT_thumball/all_white1_50x40.png",
    "text" : ""
  },
  {
    "id" : "21",
    "status" : "empty"
  },
  {
    "id" : "22",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-small",
    "name" : "dialogue 02",
    "picture_name" : "dialo-<br>gue02",
    "materials" : "canvas, acrylics",
    "size" : "60x40cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/ABSTRACT/dialoge_02_60x40.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/ABSTRACT_thumball/dialogue_02.png",
    "text" : ""
  },
  {
    "id" : "23",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-small",
    "name" : "black bottle",
    "picture_name" : "black<br>bottle",
    "materials" : "canvas, acrylics",
    "size" : "30x20cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/NATURE%20MORTE/black_bottle_30x20.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/NATURE%20MORTE_thumball/black_bottle.jpg",
    "text" : ""
  },
  {
    "id" : "24",
    "status" : "empty"
  },
  {
    "id" : "25",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-big",
    "name" : "the mask",
    "picture_name" : "the<br>mask",
    "materials" : "canvas, acrylics",
    "size" : "60x40cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/ABSTRACT/the_mask_60x40.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/ABSTRACT_thumball/The_mask.png",
    "text" : ""
  },
  {
    "id" : "26",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-small",
    "name" : "unnoticed",
    "picture_name" : "unnot-<br>iced",
    "materials" : "canvas, acrylics",
    "size" : "60x40cm",
    "year" : "2021",
    "image":"https://nnitiput.sirv.com/Images/RENAISSANCE/unnoticed_60x40.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/RENAISSANCE_thumball/unnoticed.jpg",
    "text" : ""
  },
  {
    "id" : "27",
    "status" : "empty"
  },
  {
    "id" : "28",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-big",
    "name" : "fruit of the tree",
    "picture_name" : "fruit<br>of the<br>tree",
    "materials" : "canvas, acrylics",
    "size" : "70x50cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/RENAISSANCE/fruit_of_the_tree_70x50.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/RENAISSANCE_thumball/fruit_of_the_tree.jpg",
    "text" : ""
  },
  {
    "id" : "29",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-small",
    "name" : "guard03",
    "picture_name" : "guard<br>03",
    "materials" : "canvas, acrylics",
    "size" : "80x70cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/STRANGERS/guard03_80x70.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/STRANGERS_thumball/guard03.jpg",
    "text" : ""
  },
  {
    "id" : "30",
    "status" : "empty"
  },
  {
    "id" : "31",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-small",
    "name" : "i swear",
    "picture_name" : "i<br>swear",
    "materials" : "canvas, acrylics",
    "size" : "60x45cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/ABSTRACT/i_swear_60x45.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/ABSTRACT_thumball/i_swear.png",
    "text" : ""
  },
  {
    "id" : "32",
    "collection": "full",
    "status" : "fill",
    "type" : "horizontal-small",
    "name" : "divite wind",
    "picture_name" : "divite<br>wind",
    "materials" : "paper, china ink",
    "size" : "60x40cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/GRAPHICS/divite_wind_40x40.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/GRAPHICS_thumball/divite_wind.jpg",
    "text" : ""
  },
  {
    "id" : "33",
    "status" : "empty"
  },
  {
    "id" : "34",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-big",
    "name" : "abstrct02",
    "picture_name" : "abstr-<br>ct02",
    "materials" : "canvas, acrylics",
    "size" : "80x60cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/ABSTRACT/abstrct02_80x60.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/ABSTRACT_thumball/abstrct02.png",
    "text" : ""
  },
  {
    "id" : "35",
    "collection": "full",
    "status" : "fill",
    "type" : "horizontal-small",
    "name" : "clouds",
    "materials" : "paper, china ink",
    "size" : "40x40cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/GRAPHICS/clouds_40x50.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/GRAPHICS_thumball/clouds.jpg",
    "text" : ""
  },
  {
    "id" : "36",
    "status" : "empty"
  },
  {
    "id" : "37",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-small",
    "name" : "dialogue 01",
    "picture_name" : "dialo-<br>gue 01",
    "materials" : "canvas, acrylics",
    "size" : "40x30cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/ABSTRACT/dialoge_01_40x30.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/ABSTRACT_thumball/dialogue_01.png",
    "text" : ""
  },
  {
    "id" : "38",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-big",
    "name" : "uncured",
    "picture_name" : "uncu-<br>red",
    "materials" : "canvas, acrylics",
    "size" : "70x50cm",
    "year" : "2021",
    "image": "https://nnitiput.sirv.com/Images/RENAISSANCE/uncured_70x50.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/RENAISSANCE_thumball/uncured.jpg",
    "text" : ""
  },
  {
    "id" : "39",
    "status" : "empty"
  },
  {
    "id" : "40",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-small",
    "name" : "defeat",
    "materials" : "canvas, acrylics",
    "size" : "80x60cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/STRANGERS/defeat_80x60.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/STRANGERS_thumball/defeat.jpg",
    "text" : ""
  },
  {
    "id" : "41",
    "collection": "full",
    "status" : "fill",
    "type" : "horizontal-small",
    "name" : "space03",
    "picture_name" : "space<br>03",
    "materials" : "canvas, acrylics",
    "size" : "30x20cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/ABSTRACT/space03_30x20.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/ABSTRACT_thumball/SPACE03.png",
    "text" : ""
  },
  {
    "id" : "42",
    "status" : "empty"
  },
  {
    "id" : "43",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-big",
    "name" : "lover",
    "materials" : "canvas, acrylics",
    "size" : "70x50cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/STRANGERS/lover_60x40.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/STRANGERS_thumball/lover.jpg",
    "text" : ""
  },
  {
    "id" : "44",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-small",
    "name" : "coffee thrown",
    "picture_name" : "coffee<br>thro-<br>wn",
    "materials" : "canvas, acrylics",
    "size" : "60x40cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/ABSTRACT/coffee_thrown_60x40.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/ABSTRACT_thumball/coffee_THROWN.png",
    "text" : ""
  },
  {
    "id" : "45",
    "status" : "empty"
  },
  {
    "id" : "46",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-small",
    "name" : "the oath",
    "picture_name" : "the<br>oath",
    "materials" : "paper, china ink",
    "size" : "60x40cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/GRAPHICS/the_oath_60x40.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/GRAPHICS_thumball/the_oath.jpg",
    "text" : ""
  },
  {
    "id" : "47",
    "collection": "full",
    "status" : "fill",
    "type" : "horizontal-small",
    "name" : "serpent & сhalice",
    "picture_name" : "serp-<br>ent &<br> cha-<br>lice",
    "materials" : "canvas, acrylics",
    "size" : "30x35cm",
    "year" : "2019",
    "image":"https://nnitiput.sirv.com/Images/RENAISSANCE/serpent_%26_chalice_30x35.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/RENAISSANCE_thumball/serpent_%26_chalice.jpg",
    "text" : ""
  },
  {
    "id" : "48",
    "status" : "empty"
  },
  {
    "id" : "49",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-big",
    "name" : "satyr",
    "materials" : "paper, china ink",
    "size" : "70x50cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/GRAPHICS/satyr_70x50.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/GRAPHICS_thumball/satyr.jpg",
    "text" : ""
  },
  {
    "id" : "50",
    "collection": "full",
    "status" : "fill",
    "type" : "horizontal-small",
    "name" : "It still doesn’t",
    "picture_name" : "It still<br>doe-<br>sn’t",
    "materials" : "canvas, acrylics",
    "size" : "40x30cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/ABSTRACT/it_still_doesn't_40x30.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/ABSTRACT_thumball/It_still_doesn%E2%80%99t.png",
    "text" : ""
  },
  {
    "id" : "51",
    "status" : "empty"
  },
  {
    "id" : "52",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-small",
    "name" : "h. p.",
    "materials" : "paper, china ink",
    "size" : "40x50cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/GRAPHICS/hp_40x50.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/GRAPHICS_thumball/HP.jpg",
    "text" : ""
  },
  {
    "id" : "53",
    "collection": "full",
    "status" : "fill",
    "type" : "horizontal-small",
    "name" : "the minotaur",
    "picture_name" : "the<br>mino-<br>taur",
    "materials" : "canvas, acrylics",
    "size" : "30x35cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/STRANGERS/the_minotaur_30x35.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/STRANGERS_thumball/the_minotaur.jpg",
    "text" : ""
  },
  {
    "id" : "54",
    "status" : "empty"
  },
  {
    "id" : "55",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-big",
    "name" : "cú chulainn",
    "picture_name" : "cú<br>chul-<br>ainn",
    "materials" : "paper, china ink",
    "size" : "50x60cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/GRAPHICS/c%C3%BA_chulainn_70x50.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/GRAPHICS_thumball/c%C3%BA_chulainn.jpg",
    "text" : ""
  },
  {
    "id" : "56",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-small",
    "name" : "magic",
    "materials" : "canvas, acrylics",
    "size" : "80x60cm",
    "year" : "2020",
    "image": "https://nnitiput.sirv.com/Images/ABSTRACT/magic_80x60.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/ABSTRACT_thumball/magic.png",
    "text" : ""
  },
  {
    "id" : "57",
    "status" : "empty"
  },
  {
    "id" : "58",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-big",
    "name" : "new year",
    "picture_name" : "new<br>year",
    "materials" : "canvas, acrylics",
    "size" : "60x40cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/ABSTRACT/new_year_60x40.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/ABSTRACT_thumball/new_year_60x40.png",
    "text" : ""
  },
  {
    "id" : "59",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-small",
    "name" : "uninvited",
    "picture_name" : "uninvi-<br>ted",
    "materials" : "canvas, acrylics",
    "size" : "40x50cm",
    "year" : "2021",
    "image":"https://nnitiput.sirv.com/Images/RENAISSANCE/uninvited_40x50.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/RENAISSANCE_thumball/uninvited.jpg",
    "text" : ""
  },
  {
    "id" : "60",
    "status" : "empty"
  },
  {
    "id" : "61",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-small",
    "name" : "green authentic",
    "picture_name" : "green<br>authe-<br>ntic",
    "materials" : "canvas, acrylics",
    "size" : "30x20cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/NATURE%20MORTE/green_authentic_30x20.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/NATURE%20MORTE_thumball/green_authentic.jpg",
    "text" : ""
  },
  {
    "id" : "62",
    "collection": "full",
    "status" : "fill",
    "type" : "horizontal-small",
    "name" : "hierophant",
    "picture_name" : "hiero-<br>phant",
    "materials" : "canvas, acrylics",
    "size" : "80x60cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/STRANGERS/hierophant_80x60.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/STRANGERS_thumball/Hierophant.jpg",
    "text" : ""
  },
  {
    "id" : "63",
    "status" : "empty"
  },
  {
    "id" : "64",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-big",
    "name" : "hadouken",
    "picture_name" : "hado-<br>uken",
    "materials" : "paper, china ink",
    "size" : "50x40cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/GRAPHICS/hadouken_70x50.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/GRAPHICS_thumball/hadouken.jpg",
    "text" : ""
  },
  {
    "id" : "65",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-small",
    "name" : "not wrong",
    "picture_name" : "not<br>wrong",
    "materials" : "canvas, acrylics",
    "size" : "70x50cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/NATURE%20MORTE/not_wrong_70x50.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/NATURE%20MORTE_thumball/not_wrong.jpg",
    "text" : ""
  },
  {
    "id" : "66",
    "status" : "empty"
  },
  {
    "id" : "67",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-small",
    "name" : "ex libris Vsevolod",
    "picture_name" : "ex<br>libris<br>Vsevo-<br>lod",
    "materials" : "digital",
    "size" : "30x20cm",
    "year" : "2019",
    "image":"https://nnitiput.sirv.com/Images/GRAPHICS/ex_libris_vsevolod_30x20.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/GRAPHICS_thumball/ex_libris_vsevolod.jpg",
    "text" : ""
  },
  {
    "id" : "68",
    "collection": "full",
    "status" : "fill",
    "type" : "horizontal-small",
    "name" : "the magus",
    "picture_name" : "the<br>magus",
    "materials" : "canvas, acrylics",
    "size" : "60x40cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/STRANGERS/the_magus_60x40.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/STRANGERS_thumball/the_magus.jpg",
    "text" : ""
  },
  {
    "id" : "69",
    "status" : "empty"
  },
  {
    "id" : "70",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-small",
    "name" : "abstrct04",
    "picture_name" : "abstr-<br>ct04",
    "materials" : "canvas, acrylics",
    "size" : "60x40cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/ABSTRACT/abstrct04_60x40.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/ABSTRACT_thumball/abstrct04.png",
    "text" : ""
  },
  {
    "id" : "71",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-small",
    "name" : "loki",
    "materials" : "paper, china ink",
    "size" : "40x50cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/GRAPHICS/loki_40x50.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/GRAPHICS_thumball/loki.jpg",
    "text" : ""
  },
  {
    "id" : "72",
    "status" : "empty"
  },
  {
    "id" : "73",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-big",
    "name" : "wood paper glass ",
    "picture_name" : "wood<br>paper<br>glass",
    "materials" : "canvas, acrylics",
    "size" : "60x40cm",
    "year" : "2020",
    "image": "https://nnitiput.sirv.com/Images/NATURE%20MORTE/wood_paper_glass_60x40.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/NATURE%20MORTE_thumball/wood_paper_glass.jpg",
    "text" : ""
  },
  {
    "id" : "74",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-small",
    "name" : "toad guard",
    "picture_name" : "toad<br>guard",
    "materials" : "paper, china ink",
    "size" : "40x50cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/GRAPHICS/toad_guard_40x50.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/GRAPHICS_thumball/toad_guard.jpg",
    "text" : ""
  },
  {
    "id" : "75",
    "status" : "empty"
  },
  {
    "id" : "76",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-small",
    "name" : "victory",
    "picture_name" : "victo-<br>ry",
    "materials" : "canvas, acrylics",
    "size" : "60x40cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/STRANGERS/victory_60x40.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/STRANGERS_thumball/victory.jpg",
    "text" : ""
  },
  {
    "id" : "77",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-big",
    "name" : "blue01",
    "materials" : "canvas, acrylics",
    "size" : "50x30cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/ABSTRACT/blue01_50x30.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/ABSTRACT_thumball/blue01.png",
    "text" : ""
  },
  {
    "id" : "78",
    "status" : "empty"
  },
  {
    "id" : "79",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-small",
    "name" : "werewolf",
    "picture_name" : "were-<br>wolf",
    "materials" : "paper, china ink",
    "size" : "50x50cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/GRAPHICS/werewolf_40x50.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/GRAPHICS_thumball/werewolf.jpg",
    "text" : ""
  },
  {
    "id" : "80",
    "collection": "full",
    "status" : "fill",
    "type" : "horizontal-small",
    "name" : "abstrct03",
    "picture_name" : "abstr-<br>ct03",
    "materials" : "canvas, acrylics",
    "size" : "30x40cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/ABSTRACT/abstrct03_30x29.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/ABSTRACT_thumball/abstrct03.png",
    "text" : ""
  },
  {
    "id" : "81",
    "status" : "empty"
  },
  {
    "id" : "82",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-big",
    "name" : "koyote",
    "materials" : "paper, china ink",
    "size" : "60x40cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/GRAPHICS/koyote_60x40.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/GRAPHICS_thumball/koyote.jpg",
    "text" : ""
  },
  {
    "id" : "83",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-small",
    "name" : "zhostovo",
    "picture_name" : "zhos-<br>tovo",
    "materials" : "canvas, acrylics",
    "size" : "70x50cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/NATURE%20MORTE/zhostovo_70x50.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/NATURE%20MORTE_thumball/zhostovo.jpg",
    "text" : ""
  },
  {
    "id" : "84",
    "status" : "empty"
  },
  {
    "id" : "85",
    "collection": "full",
    "status" : "fill",
    "type" : "horizontal-small",
    "name" : "Big bad wolf 01",
    "picture_name" : "big<br>bad<br>wolf<br>01",
    "materials" : "paper, china ink",
    "size" : "40x50cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/GRAPHICS/big_bad_wolf_01_40x50.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/GRAPHICS_thumball/Big_bad_wolf_01.jpg",
    "text" : ""
  },
  {
    "id" : "86",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-small",
    "name" : "no",
    "materials" : "paper, china ink",
    "size" : "30x20cm",
    "year" : "2020",
    "image": "https://nnitiput.sirv.com/Images/GRAPHICS/no_30x20.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/GRAPHICS_thumball/no.jpg",
    "text" : ""
  },
  {
    "id" : "87",
    "status" : "empty"
  },
  {
    "id" : "88",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-big",
    "name" : "no matter",
    "picture_name" : "no<br>matter",
    "materials" : "canvas, acrylics",
    "size" : "80x70cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/NATURE%20MORTE/no_matter_30x20.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/NATURE%20MORTE_thumball/no_matter.jpg",
    "text" : ""
  },
  {
    "id" : "89",
    "collection": "full",
    "status" : "fill",
    "type" : "horizontal-small",
    "name" : "shot",
    "materials" : "paper, china ink",
    "size" : "40x40cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/GRAPHICS/shot_40x50.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/GRAPHICS_thumball/shot.jpg",
    "text" : ""
  },
  {
    "id" : "90",
    "status" : "empty"
  },
  {
    "id" : "91",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-big",
    "name" : "for goya 01",
    "picture_name" : "for<br>goya 01",
    "materials" : "canvas, acrylics",
    "size" : "70x50cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/NATURE%20MORTE/for_goya_01_70x50.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/NATURE%20MORTE_thumball/for_goya_01.jpg",
    "text" : ""
  },
  {
    "id" : "92",
    "collection": "full",
    "status" : "fill",
    "type" : "horizontal-small",
    "name" : "the butler",
    "picture_name" : "the<br>butler",
    "materials" : "paper, china ink",
    "size" : "40x40cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/GRAPHICS/the_butler_40x50.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/GRAPHICS_thumball/the_butler.jpg",
    "text" : ""
  },
  {
    "id" : "93",
    "status" : "empty"
  },
  {
    "id" : "94",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-small",
    "name" : "mushrooms",
    "picture_name" : "mush-<br>rooms",
    "materials" : "paper, china ink",
    "size" : "60x40cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/GRAPHICS/mushrooms_70x50.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/GRAPHICS_thumball/mushrooms.jpg",
    "text" : ""
  },
  {
    "id" : "95",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-big",
    "name" : "white red",
    "picture_name" : "white<br>red",
    "materials" : "canvas, acrylics",
    "size" : "60x40cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/ABSTRACT/white_red_60x40.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/ABSTRACT_thumball/white_red.png",
    "text" : ""
  },
  {
    "id" : "96",
    "status" : "empty"
  },
  {
    "id" : "97",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-small",
    "name" : "legs",
    "materials" : "canvas, acrylics",
    "size" : "30x20cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/ABSTRACT/legs_30x20.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/ABSTRACT_thumball/legs.png",
    "text" : ""
  },
  {
    "id" : "98",
    "collection": "full",
    "status" : "fill",
    "type" : "horizontal-small",
    "name" : "scent of burnt rubber",
    "picture_name" : "scent<br>of<br>burnt<br>rub-<br>ber",
    "materials" : "digital",
    "size" : "40x50cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/GRAPHICS/scent_of_burnt_rubber_40x50.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/GRAPHICS_thumball/scent_of_burnt_rubber.jpg",
    "text" : ""
  },
  {
    "id" : "99",
    "status" : "empty"
  },
  {
    "id" : "100",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-big",
    "name" : "big bad wolf 02",
    "picture_name" : "big<br>bad<br>wolf<br>02",
    "materials" : "paper, china ink",
    "size" : "40x50cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/GRAPHICS/big_bad_wolf_02_30x20.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/GRAPHICS_thumball/big_bad_wolf_02.jpg",
    "text" : ""
  },
  {
    "id" : "101",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-small",
    "name" : "cards",
    "materials" : "canvas, acrylics",
    "size" : "70x50cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/ABSTRACT/cards_70x50.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/ABSTRACT_thumball/cards.png",
    "text" : ""
  },
  {
    "id" : "102",
    "status" : "empty"
  },
  {
    "id" : "103",
    "collection": "full",
    "status" : "fill",
    "type" : "horizontal-small",
    "name" : "brennt",
    "picture_name" : "bren-<br>nt",
    "materials" : "paper, china ink",
    "size" : "40x50cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/GRAPHICS/brennt_40x50.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/GRAPHICS_thumball/brennt.jpg",
    "text" : ""
  },
  {
    "id" : "104",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-big",
    "name" : "THE BULL",
    "picture_name" : "the<br>bull",
    "materials" : "canvas, acrylics",
    "size" : "80x40cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/ABSTRACT/the_bull_80x40.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/ABSTRACT_thumball/THE_BULL.png",
    "text" : ""
  },
  {
    "id" : "105",
    "status" : "empty"
  },
  {
    "id" : "106",
    "collection": "full",
    "status" : "fill",
    "type" : "horizontal-small",
    "name" : "abstrct01",
    "picture_name" : "abstr-<br>ct01",
    "materials" : "canvas, acrylics",
    "size" : "50x40cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/ABSTRACT/abstrct01_50x40.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/ABSTRACT_thumball/abstrct01.png",
    "text" : ""
  },
  {
    "id" : "107",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-big",
    "name" : "thor",
    "materials" : "paper, china ink",
    "size" : "40x30cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/GRAPHICS/thor_40x50.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/GRAPHICS_thumball/thor.jpg",
    "text" : ""
  },
  {
    "id" : "108",
    "status" : "empty"
  },
  {
    "id" : "109",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-small",
    "name" : "Space 01",
    "picture_name" : "space<br>01",
    "materials" : "canvas, acrylics",
    "size" : "80x70cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/ABSTRACT/space_01_80x70.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/ABSTRACT_thumball/Space_01.png",
    "text" : ""
  },
  {
    "id" : "110",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-big",
    "name" : "opposite",
    "picture_name" : "oppo-<br>site",
    "materials" : "canvas, acrylics",
    "size" : "80x40cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/ABSTRACT/opposite_80x40.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/ABSTRACT_thumball/opposite.png",
    "text" : ""
  },
  {
    "id" : "111",
    "status" : "empty"
  },
  {
    "id" : "112",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-small",
    "name" : "Angel-sofa",
    "picture_name" : "Angel-<br>sofa",
    "materials" : "canvas, acrylics",
    "size" : "60x40cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/ABSTRACT/angel_sofa_60x40.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/ABSTRACT_thumball/angel-sofa.png",
    "text" : ""
  },
  {
    "id" : "113",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-big",
    "name" : "Light industry",
    "picture_name" : "Light<br>indu-<br>stry",
    "materials" : "canvas, acrylics",
    "size" : "80x60cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/ABSTRACT/light_industry_80x60.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/ABSTRACT_thumball/Light%20industry.png",
    "text" : ""
  },
  {
    "id" : "114",
    "status" : "empty"
  },
  {
    "id" : "115",
    "collection": "full",
    "status" : "fill",
    "type" : "horizontal-small",
    "name" : "Want won’t get",
    "picture_name" : "Want<br>won’t<br>get",
    "materials" : "canvas, acrylics",
    "size" : "30x35cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/ABSTRACT/want_won't_get_30x35.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/ABSTRACT_thumball/want_won%E2%80%99t_get.png",
    "text" : ""
  },
  {
    "id" : "116",
    "collection": "full",
    "status" : "fill",
    "type" : "vertical-small",
    "name" : "helmet 1",
    "picture_name" : "helmet<br>1",
    "materials" : "canvas, acrylics",
    "size" : "35x40cm",
    "year" : "2021",
    "image":"https://nnitiput.sirv.com/Images/RENAISSANCE/helmet1.JPG",
    "thumbnail" : "https://nnitiput.sirv.com/Images/RENAISSANCE_thumball/helmet1.jpg",
    "text" : ""
    }
]



  ;