module.exports = [
  {
    "collection_name": "https://nnitiput.sirv.com/Images/name_collection/NATURE_MORTE.png",
    "collection_description": "I love the classical Dutch still-life. And as with everything I love, I deconstruct it to bits. Also, it’s sometimes just makes sense to paint what’s before one’s eyes. Or behind them."
  },
  {
    "id" : "1",
    "collection": "nature_morte",
    "status" : "fill",
    "type" : "vertical-small",
    "name" : "black bottle",
    "picture_name" : "black<br>bottle",
    "materials" : "canvas, acrylics",
    "size" : "30x20cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/NATURE%20MORTE/black_bottle_30x20.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/NATURE%20MORTE_thumball/black_bottle.jpg",
    "text" : ""
  },
  {
    "id" : "2",
    "collection": "nature_morte",
    "status" : "fill",
    "type" : "horizontal-small",
    "name" : "through the window",
    "picture_name" : "throu-<br>gh the<br>wind-<br>ow",
    "materials" : "canvas, acrylics",
    "size" : "40x40cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/NATURE%20MORTE/through_the_window_40x40.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/NATURE%20MORTE_thumball/through_the_window.jpg",
    "text" : ""
  },
  {
    "id" : "3",
    "status" : "empty"
  },
  {
    "id" : "4",
    "collection": "nature_morte",
    "status" : "fill",
    "type" : "vertical-small",
    "name" : "zhostovo",
    "picture_name" : "zhos-<br>tovo",
    "materials" : "canvas, acrylics",
    "size" : "70x50cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/NATURE%20MORTE/zhostovo_70x50.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/NATURE%20MORTE_thumball/zhostovo.jpg",
    "text" : ""
  }, 
  {
    "id" : "5",
    "collection": "nature_morte",
    "status" : "fill",
    "type" : "vertical-big",
    "name" : "goatman",
    "picture_name" : "goat-<br>man",
    "materials" : "canvas, acrylics",
    "size" : "70x50cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/NATURE%20MORTE/goatman_70x50.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/NATURE%20MORTE_thumball/goatman.jpg",
    "text" : ""
  },
  {
    "id" : "6",
    "status" : "empty"
  },
  {
    "id" : "7",
    "collection": "nature_morte",
    "status" : "fill",
    "type" : "vertical-big",
    "name" : "wood paper glass ",
    "picture_name" : "wood<br>paper<br>glass",
    "materials" : "canvas, acrylics",
    "size" : "60x40cm",
    "year" : "2020",
    "image": "https://nnitiput.sirv.com/Images/NATURE%20MORTE/wood_paper_glass_60x40.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/NATURE%20MORTE_thumball/wood_paper_glass.jpg",
    "text" : ""
  },
  {
    "id" : "8",
    "collection": "nature_morte",
    "status" : "fill",
    "type" : "horizontal-small",
    "name" : "dutch01",
    "picture_name" : "dutch<br>01",
    "materials" : "canvas, acrylics",
    "size" : "40x50cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/NATURE%20MORTE/dutch01_40x50.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/NATURE%20MORTE_thumball/dutch01.jpg",
    "text" : ""
  },
  {
    "id" : "9",
    "status" : "empty"
  },
  {
    "id" : "10",
    "collection": "nature_morte",
    "status" : "fill",
    "type" : "vertical-small",
    "name" : " not wrong",
    "picture_name" : "not<br>wrong",
    "materials" : "canvas, acrylics",
    "size" : "70x50cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/NATURE%20MORTE/not_wrong_70x50.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/NATURE%20MORTE_thumball/not_wrong.jpg",
    "text" : ""
  },

  {
    "id" : "11",
    "collection": "nature_morte",
    "status" : "fill",
    "type" : "vertical-big",
    "name" : "for goya 01",
    "picture_name" : "for<br>goya 01",
    "materials" : "canvas, acrylics",
    "size" : "70x50cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/NATURE%20MORTE/for_goya_01_70x50.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/NATURE%20MORTE_thumball/for_goya_01.jpg",
    "text" : ""
  },
  {
    "id" : "12",
    "status" : "empty"
  },
  {
    "id" : "13",
    "collection": "nature_morte",
    "status" : "fill",
    "type" : "vertical-small",
    "name" : "green authentic",
    "picture_name" : "green<br>authe-<br>ntic",
    "materials" : "canvas, acrylics",
    "size" : "30x20cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/NATURE%20MORTE/green_authentic_30x20.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/NATURE%20MORTE_thumball/green_authentic.jpg",
    "text" : ""
  },
  {
    "id" : "14",
    "collection": "nature_morte",
    "status" : "fill",
    "type" : "vertical-big",
    "name" : "no matter",
    "picture_name" : "no<br>matter",
    "materials" : "canvas, acrylics",
    "size" : "80x70cm",
    "year" : "2020",
    "image":"https://nnitiput.sirv.com/Images/NATURE%20MORTE/no_matter_30x20.png",
    "thumbnail" : "https://nnitiput.sirv.com/Images/NATURE%20MORTE_thumball/no_matter.jpg",
    "text" : ""
  },
  {
    "id" : "15",
    "status" : "empty"
  }
]
;